export default function useAccordion() {
  /********************
   * REFS & VARS       *
   ********************/
  const selected = shallowRef<number[]>([]);

  /********************
   * FUNCTIONS         *
   ********************/
  function onToggle(index: number) {
    if (isOpen(index)) {
      selected.value = selected.value.filter(idx => idx !== index);
    } else {
      selected.value = [...selected.value, index];
    }
  }

  function isOpen(index: number) {
    return selected.value.includes(index);
  }

  return {
    selected,
    onToggle,
    isOpen,
  };
}
